<template>
	<div>
		<div class="info-bg" :style="{height:maxheight}">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="200px">
					<el-form-item label="封面图">
						<span class="xx">*</span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<img v-if="ruleForm.thumb" class="dialog-img" :src="ruleForm.thumb" />
							<p v-if="ruleForm.thumb" class="dele" @click="dele">删除</p>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="handleAvatarSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
						<div style="float: right;" @click="$router.go(-1)">
							<el-button size="mini"><i class="icon iconfont icon-fanhui"></i>返回上一步</el-button>
						</div>
					</el-form-item>
					<el-form-item label="轮播图">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="imgList.length>0" v-for="(item,index) in imgList" :key="index">
								<img class="dialog-img" :src="item" />
								<p class="dele" @click="deleImg(index)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="imgSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="名称">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入名称"></el-input>
					</el-form-item>
					<el-form-item label="详细地址">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.address" placeholder="请输入详细地址"></el-input>
					</el-form-item>
					<el-form-item label="宅基地">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.homestead" placeholder="请输入宅基地"></el-input>
					</el-form-item>
					<el-form-item label="耕地">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.plough" placeholder="请输入耕地"></el-input>
					</el-form-item>
					<el-form-item label="园地">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.garden_plot" placeholder="请输入园地"></el-input>
					</el-form-item>
					<el-form-item label="生态林">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.ecological_forest" placeholder="请输入生态林"></el-input>
					</el-form-item>
					<el-form-item label="建设用地">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.land_used_build" placeholder="请输入建设用地"></el-input>
					</el-form-item>
					<el-form-item label="农村居民点用地">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.residential_land" placeholder="请输入农村居民点用地"></el-input>
					</el-form-item>
					<el-form-item label="基本农田">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.basic_farmland" placeholder="请输入基本农田"></el-input>
					</el-form-item>
					<el-form-item label="机电设备">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.jidianshebei" placeholder="请输入机电设备"></el-input>
					</el-form-item>
					<el-form-item label="农田水利">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.nongtianshuili" placeholder="请输入农田水利"></el-input>
					</el-form-item>
					<el-form-item label="旅游设施">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.facility_traveling" placeholder="请输入旅游设施"></el-input>
					</el-form-item>
					<el-form-item label="产役畜">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.changyixv" placeholder="请输入产役畜"></el-input>
					</el-form-item>
					<el-form-item label="教育">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.education" placeholder="请输入教育"></el-input>
					</el-form-item>
					<el-form-item label="电视">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.television" placeholder="请输入电视"></el-input>
					</el-form-item>
					<el-form-item label="体育设施">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.sports_facilities" placeholder="请输入体育设施"></el-input>
					</el-form-item>
					<el-form-item label="村集体自主经营性收入">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.total_operating_income" placeholder="请输入村集体自主经营性收入">
						</el-input>
					</el-form-item>
					<el-form-item label="农村居民人均收入可支配收入">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.per_capita_income" placeholder="请输入农村居民人均收入可支配收入">
						</el-input>
					</el-form-item>
					<el-form-item label="户籍户数">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.households" placeholder="请输入户籍户数"></el-input>
					</el-form-item>
					<el-form-item label="常住人口数">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.changzhurenkou" placeholder="请输入常住人口数"></el-input>
					</el-form-item>
					<el-form-item label="16-60岁常住人口">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.changzhurenkou_sixteen_to_sixty"
							placeholder="请输入16-60岁常住人口"></el-input>
					</el-form-item>
					<el-form-item label="60岁以上常住人口">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.changzhurenkou_sixty" placeholder="请输入60岁以上常住人口">
						</el-input>
					</el-form-item>
					<el-form-item label="地形地貌">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.topographic_features" placeholder="请输入地形地貌"></el-input>
					</el-form-item>
					<el-form-item label="村域面积">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.village_area" placeholder="请输入村域面积"></el-input>
					</el-form-item>
					<el-form-item label="耕地面积">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.cultivated_land_area" placeholder="请输入耕地面积"></el-input>
					</el-form-item>
					<el-form-item label="林地面积">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.forest_land_area" placeholder="请输入林地面积"></el-input>
					</el-form-item>
					<el-form-item label="山城田面积">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.area_shanchengtian" placeholder="请输入山城田面积"></el-input>
					</el-form-item>
					<el-form-item label="农村住房总户数">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.zhufanghushu" placeholder="请输入农村住房总户数"></el-input>
					</el-form-item>
					<el-form-item label="常年无人居住住房户数">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.unmanned_households" placeholder="请输入常年无人居住住房户数">
						</el-input>
					</el-form-item>
					<el-form-item label="省级以上农民专业合作示范社数量">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.hezuoshifansheshuliang" placeholder="请输入省级以上农民专业合作示范社数量">
						</el-input>
					</el-form-item>
					<el-form-item label="省级以上家庭农场数量">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.family_farms_number" placeholder="请输入省级以上家庭农场数量">
						</el-input>
					</el-form-item>
					<el-form-item label="集中供水率">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.water_supply_rate" placeholder="请输入集中供水率"></el-input>
					</el-form-item>
					<el-form-item label="垃圾收集处理率">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.garbage_c_a_t_rate" placeholder="请输入垃圾收集处理率"></el-input>
					</el-form-item>
					<el-form-item label="污水处理率">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.sewage_treatment_rate" placeholder="请输入污水处理率"></el-input>
					</el-form-item>
					<el-form-item label="年垃圾处理率">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.annual_waste_treatment_rate" placeholder="请输入年垃圾处理率">
						</el-input>
					</el-form-item>
					<el-form-item label="公共垃圾收集桶">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.garbage_collection_bin" placeholder="请输入公共垃圾收集桶">
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<!-- 图文详情 -->
			<content ref="content" :title="title" :contentText="ruleForm.content" @Submitinput="Submitinput">
			</content>
		</div>
		<div class="dialog-footer" v-if="!id">
			<el-button size="mini" @click="Reset">重置</el-button>
			<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
			</el-button>
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/village";
	import content from '@/components/content.vue'
	export default {
		components: {
			content
		},
		data() {
			return {
				Headers: {
					token: ''
				},
				ActionUrl: '',
				ruleForm: {
					name: '',
					thumb: '',
					address: '',
					imgpath: '',
					homestead: '',
					plough: '',
					garden_plot: '',
					ecological_forest: '',
					land_used_build: '',
					residential_land: '',
					basic_farmland: '',
					jidianshebei: '',
					nongtianshuili: '',
					facility_traveling: '',
					changyixv: '',
					education: '',
					television: '',
					sports_facilities: '',
					total_operating_income: '',
					per_capita_income: '',
					households: '',
					households: '',
					changzhurenkou: '',
					changzhurenkou_sixteen_to_sixty: '',
					changzhurenkou_sixty: '',
					topographic_features: '',
					village_area: '',
					cultivated_land_area: '',
					forest_land_area: '',
					area_shanchengtian: '',
					building_land_area: '',
					zhufanghushu: '',
					unmanned_households: '',
					hezuoshifansheshuliang: '',
					family_farms_number: '',
					water_supply_rate: '',
					sewage_treatment_rate: '',
					annual_waste_treatment_rate: '',
					garbage_collection_bin: '',
					garbage_c_a_t_rate: '',
					id: ''
				},
				maxheight: document.documentElement.clientHeight - 180 + 'px',
				imgList: [],
				id: ''
			}
		},
		mounted() {
			// 上转图片url
			this.Headers.token = sessionStorage.getItem('token')
			this.Headers.appid = sessionStorage.getItem('appid')
			this.Headers.nonce = sessionStorage.getItem('nonce')
			this.Headers.timestamp = sessionStorage.getItem('timestamp')
			this.Headers.sign =sessionStorage.getItem('sign')
			
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/Upload/FormUpload'
		},
		created() {
			if (this.$route.query.id) {
				this.id = this.$route.query.id
			}
			this.getinfo()
		},
		methods: {
			// 删除轮播图片
			deleImg(index) {
				this.imgList.splice(index, 1)
			},
			// 轮播图片
			imgSuccess(res, file) {
				this.imgList.push(res.data.url)
			},
			// 删除图片
			dele() {
				this.ruleForm.thumb = ''
			},
			// 上转图片
			handleAvatarSuccess(res, file) {
				this.ruleForm.thumb = res.data.url
			},
			// 图文详情
			Submitinput(e) {
				this.ruleForm.content = e
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: this.id||this.$store.state.villageId
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data

							this.imgList = res.data.imgpath ? res.data.imgpath.split(",") : []
							// 赋值
							this.$refs.content.setContent(res.data.content)
							delete this.ruleForm.create_time
						}
					})
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				delete this.ruleForm.id
				delete this.ruleForm.delete_time
			},
			//编辑
			submitForm() {
				this.ruleForm.imgpath = this.imgList.join(",")
				if (this.ruleForm.imgpath === '') {
					ElMessage({
						message: '请上传封面图',
						type: 'warning',
					})
				} else if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入名称',
						type: 'warning',
					})
				} else if (this.ruleForm.address === '') {
					ElMessage({
						message: '请输入详细地址',
						type: 'warning',
					})
				} else {
					this.edit()
				}
			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.getinfo()
						}
					})
			}
		}
	}
</script>

<style scoped lang="scss">
	.dialog-footer {
		background-color: #fff;
		padding: 20px;
	}

	.info-bg {
		background-color: #fff;
		overflow-y: auto;
		padding: 20px;

	}
</style>